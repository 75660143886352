<template>
  <PostsList
    :resource="resource"
    :columns="headers"
    title="Список НАШИХ УСЛОВИЙ ДОСТАВКИ ГРУЗОВ"
    :add-route="{ name: 'terms-post' }"
    store="termsPosts"
    path="terms"
  />
</template>

<script>
import PostsList from "../components/PostsList.vue";

export default {
  components: {
    PostsList,
  },
  data() {
    return {
      resource: "posts/terms",
      headers: [
        { text: "Ид",name: "id", width: "30", maxLength: 10, dataPath: "id" },
        { text: "Дата создания", type: "date", name: "date", width: "30", maxLength: 10, dataPath: "createdAt" },
        { text: "Наименование", name: "text", width: "250", maxLength: 25, dataPath: "title" },
        { text: "Автор", type: "object", name: "author", width: "150", maxLength: 15, dataPath: "author" },
        {
          text: "Опубликовано",
          name: "isPublished",
          width: "80",
          maxLength: 10,
          valueFn(i) {
            return i.isPublished ? "Да" : "Нет";
          },
        },
        { text: "", sortable: false, type: "action", width: "130" },
      ],
    };
  },
};
</script>
